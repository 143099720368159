<template>
  <NuxtLink :to="localePath(path)"><slot /></NuxtLink>
</template>

<script setup lang="ts">
import { normalize } from "~/utils/url/normalize";
import { getLocale } from "~/utils/url/getLocale";

type Props = {
  to: string;
  considerPlatform?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  considerPlatform: true,
});

// useLocalePath модуля @nuxtjs/i18n вызывает warning
// при использовании глобальных мидлваров
const localePath = (path: string) => {
  if (getLocale(path)) {
    return path;
  } else {
    const { locale } = useI18n();
    if (locale.value === "ru") {
      return path;
    } else {
      return normalize(`/${locale.value}/${props.to}`);
    }
  }
};

const path = computed(() => {
  if (!props.considerPlatform) return normalize(`/${props.to}`);
  const platform = usePlatform();
  const currentPlatformCode = platform
    ? platform.is.default
      ? ""
      : platform.code
    : "";
  const allPlatformsCodes = Object.keys(
    platform ? platform.ALL_PLATFORMS_DATA : {},
  );
  const isSpecial = allPlatformsCodes.some((platformCode) =>
    props.to.match(new RegExp(`^((/\\w{2,3})?/${platformCode})(/.*)?`)),
  );
  if (isSpecial) {
    return normalize(`/${props.to}`);
  } else {
    return normalize(`/${currentPlatformCode}/${props.to}`);
  }
});
</script>
